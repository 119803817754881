import React, { Component, useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { AppContext } from "../../context/AppContext";
import { getFormattedCart } from "../../../utils/functions";
import Link from "gatsby-link";
import { v4 } from "uuid";
//import ScriptTag from 'react-script-tag';

/*const Demo = props => (
<ScriptTag type="text/javascript" src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/js/page/bundle.js" />
)*/
//import GET_CART from "../../../queries/get-cart";
//import ADD_TO_CART from "../../../mutations/add-to-cart";
//import "./style.scss";

class AddtoBundle extends Component {
    handleClick() {
      console.log('Click happened');
    }
    render() {
      return <button onClick={this.handleClick.bind(this)} class="btn btn-lg d-flex justify-content-center align-items-center" role="button" aria-pressed="true">Add to Bundle and Save 
                                    </button>
    }
  }

  export default AddtoBundle;