import React from "react";
import AddToCartButton from "../cart/add-to-cart-button";
import AddtoBundle from "../cart/add-to-bundle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isEmpty } from "lodash";
import SocialShareCard from "../social-share-card";
import ProductCarousel from "../product-carousel";
import { sanitize } from "../../utils/functions";
import ReviewDisplay from "../power-reviews/review-display"
import ReviewSnippet from "../power-reviews/review-snippet"
import "./style.scss";

const productImagePlaceholder = "https://via.placeholder.com/434";

const SingleProduct = ( props ) => {
	const { product } = props;

	const hasImagesSizes =
		      !isEmpty( product.image ) && !isEmpty( product.image.mediaDetails.sizes );
	const imgSrcUrl      = hasImagesSizes
		? product.image.sourceUrl
		: "";

	const displayProductImages = () => {
		if ( !isEmpty( product.galleryImages.nodes ) ) {
			return <ProductCarousel galleryImages={ product.galleryImages }/>
		} else if ( !isEmpty( product.image ) ) {
			return (
				<figure>
					<LazyLoadImage
						alt={ product.image.altText ? product.image.altText : "" }
						src={ imgSrcUrl } // use normal <img> attributes as props
						effect="blur"
					/>
				</figure>
			)
		} else if ( !isEmpty( productImagePlaceholder ) ) {
			return (
				<figure>
					<LazyLoadImage
						alt="default"
						height="450"
						src={ productImagePlaceholder }
						width="450"
						effect="blur"
					/>
				</figure>
			)
		} else {
			return null;
		}
	}
	

	return (
		// @TODO Need to handle Group products differently.
		!isEmpty( product ) && "GroupProduct" !== product.nodeType ? (
			<div className="cs-page-content-body">
				<div className="product-details-page">
					<div className="container-fluid cs-product-details">
						<div className="row justify-content-between">
							<div className="col-12 col-md-6 cs-product-gallery">
								{ displayProductImages() }
							</div>

							<div className="col-12 col-md-6 cs-product-overview product-new-overview">
								<div className="overview">
								<ReviewSnippet/>
									<div className="title">Moisturizers</div>

									<div className="title-desc">{ product.name ? product.name : "" }</div>
									
									<div className="single-product-price">{ product.price }</div>

									{ !isEmpty( product.description ) ? (
										<div className="product-desc">
											<p dangerouslySetInnerHTML={ { __html: sanitize( product.description ) } }/>
										</div>
									) : null }
									<div className="add-cart d-flex button-change">
										<AddToCartButton product={ product }/>
									</div>
									<div class="add-bundle-btn d-flex button-change-two">
                                        <AddtoBundle/>
                                	</div>
									<SocialShareCard title={ product.name } sectionTitle="Share this product"
													link={ product.uri }/>
								    
									
								</div>
							</div>
						</div>
					</div>
					<div class="container-fluid cs-img-box philosophy">
        <div class="d-flex">
            <div class="cs-content">
                <div class="title">
                    Carrot &amp; Stick
                </div>
                <div class="head">
                    <span>Goodbye, toxic chemicals, Hello, Plant-Powered Beauty</span>.
                </div>
                <div class="overview">
                    CARROT &amp; STICK WILL NEVER COMPROMISE YOUR BEAUTY OR YOUR VALUES.
                </div>
                <div class="cs-btn d-flex">
                    <a href="https://cs1testdev.wpengine.com/about/" class="btn btn-lg d-flex justify-content-between" role="button" aria-pressed="true">
                        <span class="d-none d-md-block">Read about our tough love approach</span>
                        <span class="d-block d-md-none">Read more</span>
                        <img src="https://cs1testdev.wpengine.com/wp-content/themes/xejic_carrot_stick/assets/icons/arrow-32-px.svg" alt="" loading="lazy"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
					<div class="container-fluid cs-col-content-img write_review_sec">
					<div class="row align-items-center">
					<div class="review-header">
						<h2>Reviews</h2>
					</div>
            		<div class="col-12 order-1 col-md-12 order-md-2 cs-content-details">
					<ReviewDisplay/>
					</div>
					</div>
					</div>
				</div>
			</div>
		) : null
	);
};

export default SingleProduct;
